const svg = {
  github: {
    path: 'M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12',
    fill: true,
  },
  twitter: {
    path: 'M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z',
    fill: true,
  },
  xing: {
    path: 'M18.188 0c-.517 0-.741.325-.927.66 0 0-7.455 13.224-7.702 13.657.015.024 4.919 9.023 4.919 9.023.17.308.436.66.967.66h3.454c.211 0 .375-.078.463-.22.089-.151.089-.346-.009-.536l-4.879-8.916a.022.022 0 010-.022L22.139.756c.095-.191.097-.387.006-.535C22.056.078 21.894 0 21.686 0h-3.498zM3.648 4.74c-.211 0-.385.074-.473.216-.09.149-.078.339.02.531l2.34 4.05c.004.01.004.016 0 .021L1.86 16.051a.52.52 0 000 .529c.085.142.239.234.45.234h3.461c.518 0 .766-.348.945-.667l3.734-6.609-2.378-4.155c-.172-.315-.434-.659-.962-.659H3.648v.016z',
    fill: true,
  },
  youtube: {
    path: 'M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z',
    fill: true,
  },
  linkedin: {
    path: 'M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z',
    fill: true,
  },
  compass: {
    path: 'M19.053 21.708c5.362-3.895 6.55-11.4 2.655-16.761-3.895-5.362-11.4-6.55-16.762-2.655-5.361 3.895-6.55 11.4-2.654 16.761 3.895 5.362 11.4 6.55 16.761 2.655zM9.977 13.47L6.71 4.719l7.313 5.811 3.267 8.751-7.313-5.811zm2.728-.5a1.2 1.2 0 10-1.41-1.94 1.2 1.2 0 001.41 1.94z',
    fill: true,
  },
  mail: {
    path: 'M4 20a3 3 0 01-3-3V7.012a3 3 0 013.035-3L12 4.105h8a3 3 0 013 3V17a3 3 0 01-3 3H4zM2.222 6.258a.375.375 0 00.063.527l8.247 6.487a2.375 2.375 0 002.936 0l8.247-6.487a.375.375 0 10-.464-.59l-8.246 6.487c-.59.464-1.42.464-2.01 0L2.75 6.196a.375.375 0 00-.527.062z',
    fill: true,
  },
  tag: {
    path: 'M4.25 4.5C4.25 4.36193 4.36193 4.25 4.5 4.25H11.0858C11.1521 4.25 11.2157 4.27634 11.2626 4.32322L16.4697 9.53033L20.785 13.8457C20.8804 13.9411 20.8829 14.095 20.7906 14.1934L14.6592 20.7336C14.5626 20.8366 14.3999 20.8393 14.3 20.7394L4.32322 10.7626C4.27634 10.7157 4.25 10.6521 4.25 10.5858V4.5ZM4.5 2.75C3.5335 2.75 2.75 3.5335 2.75 4.5V10.5858C2.75 11.0499 2.93437 11.495 3.26256 11.8232L13.2394 21.8C13.9384 22.4991 15.0774 22.4807 15.7535 21.7595L21.8849 15.2193C22.5309 14.5303 22.5136 13.4529 21.8457 12.785L17.5303 8.46967L12.3232 3.26256C11.995 2.93437 11.5499 2.75 11.0858 2.75H4.5ZM8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z',
    fill: true,
  },
  externalLink: {
    path: 'M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14',
    stroke: true,
  },
  rss: {
    path: 'M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z',
    stroke: true,
    strokeWidth: '2',
  },
  slides: {
    path: 'M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z',
    stroke: true,
    strokeWidth: '2',
  },
  video: {
    path: 'M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z',
    stroke: true,
    strokeWidth: '2',
  },
  microphone: {
    path: 'M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z',
    fill: true,
  },
  pencil: {
    path: 'M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z',
    stroke: true,
    strokeWidth: '2',
  },
  mastodon: {
    path: 'M22.74 14.36c-.3 1.66-3 3.52-6 3.93-1.54.2-3.1.31-4.74.31-2.7-.2-4.76-.72-4.76-.72v.82c.42 2.59 2.69 2.8 4.86 2.9 2.17 0 4.03-.62 4.03-.62l.1 1.96s-1.44.83-4.23 1.03c-1.45.1-3.31-.1-5.48-.62C1.77 22.11.94 17.05.84 12V7.85c0-5.16 3.4-6.71 3.4-6.71C6 .3 8.9 0 12 0h.1c3 0 6 .31 7.65 1.14 0 0 3.4 1.55 3.4 6.71 0 0 .11 3.83-.4 6.51Zm-3.51-6.1c0-1.23-.41-2.27-1.03-3.1a3.58 3.58 0 0 0-2.7-1.13 3.2 3.2 0 0 0-2.88 1.55L12 6.61l-.62-1.03c-.62-1.03-1.55-1.55-2.9-1.55-1.03 0-1.96.41-2.68 1.14a5.02 5.02 0 0 0-.93 3.1v6.3h2.48v-6.1c0-1.34.51-1.96 1.65-1.96s1.76.83 1.76 2.27v3.41h2.48V8.8c0-1.45.62-2.28 1.86-2.28 1.03 0 1.55.62 1.55 1.96v6.1h2.58v-6.3Z',
    fill: true,
  },
  bluesky: {
    path: 'M24 3.73A3.73 3.73 0 0 0 20.27 0H3.73A3.73 3.73 0 0 0 0 3.73v16.54A3.73 3.73 0 0 0 3.73 24h16.54A3.73 3.73 0 0 0 24 20.27V3.73Z',
    fill: true,
  },
};

export default svg;
